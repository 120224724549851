import { useState, useEffect } from 'react';
import BottomBar from './BottomBar';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Accordion, Button, Form, Card } from 'react-bootstrap';
import ReactSnackBar from 'react-js-snackbar';
import { uglifyText } from './textUglifier';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';

function App() {
    ReactGA.initialize('UA-185250602-3');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const [asItSoundsDegree, setAsItSoundsDegree] = useState(50);
    const [
        doubleConsonantAsEndSoundDegree,
        setDoubleConsonantAsEndSoundDegree,
    ] = useState(50);
    const [componentChangeDegree, setComponentChangeDegree] = useState(50);
    const [addExtraEndSoundDegree, setAddExtraEndSoundDegree] = useState(50);
    const [onlyWansungChars, setOnlyWansungChars] = useState(true);
    const [input, setInput] = useState(
        '해외여행 중 방문했던 숙박 시설이나 음식점 후기를 남길 때, 혹은 해외 체류 중 외국인 친구 모르게 글을 쓰고 싶을 때 유용한 방법이 있다. 지구상 어떤 번역기도 읽을 수 없는 일명 ‘에어비엔비 체’다. 에어비엔비 숙소 주인 몰래 한국인만 알아채도록 후기를 남긴 데서 비롯됐다. 모음과 자음의 다양한 조합으로 여러 경우의 수를 만들어내는 방식이다.'
    );
    const [output, setOutput] = useState('');
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [snackbarShowing, setSnackbarShowing] = useState(false);

    useEffect(() => {
        convertText();
    }, [
        asItSoundsDegree,
        doubleConsonantAsEndSoundDegree,
        componentChangeDegree,
        addExtraEndSoundDegree,
        onlyWansungChars,
    ]);

    const handleInputChange = (e) => {
        e.preventDefault();
        setInput(e.target.value);
    };
    const handleOutputChange = (e) => {
        e.preventDefault();
        setOutput(e.target.value);
    };
    const handleChangeForAsItSoundsDegree = (e) => {
        setAsItSoundsDegree(e.target.value);
    };
    const handleChangeForDoubleConsonantAsEndSoundDegree = (e) => {
        setDoubleConsonantAsEndSoundDegree(e.target.value);
    };
    const handleChangeForComponentChangeDegree = (e) => {
        setComponentChangeDegree(e.target.value);
    };
    const handleChangeForAddExtraEndSoundDegree = (e) => {
        setAddExtraEndSoundDegree(e.target.value);
    };
    const handleChangeForOnlyWansungChars = (e) => {
        setOnlyWansungChars(e.target.checked);
    };
    const clearText = (e) => {
        setInput('');
        setOutput('');
    };
    const convertText = (e) => {
        setOutput(
            uglifyText(
                input,
                asItSoundsDegree,
                doubleConsonantAsEndSoundDegree,
                componentChangeDegree,
                addExtraEndSoundDegree,
                onlyWansungChars
            )
        );
    };
    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        document.body.appendChild(textArea);
        textArea.value = text;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };
    const showSnackbar = () => {
        if (snackbarShowing) {
            return;
        }
        setSnackbarShowing(true);
        setSnackbarShow(true);
        setTimeout(() => {
            setSnackbarShow(false);
        }, 2000);
        setTimeout(() => {
            setSnackbarShowing(false);
        }, 3600);
    };
    const copyOutputToClipboard = (e) => {
        copyToClipboard(output);
        showSnackbar();
    };

    return (
        <>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-12 m-0 pb-0">
                        <h1 className="display-4 my-2">
                            <a
                                href="https://airbnbfy.hanmesoft.com"
                                className="text-decoration-none"
                            >
                                한글 난독화
                            </a>
                        </h1>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-6 col-xl-4 m-0 py-0 px-3">
                        <textarea
                            className="form-control my-2"
                            placeholder="변환할 문장들을 여기에 입력하고 '변환하기' 버튼을 누르세요."
                            rows="8"
                            onChange={handleInputChange}
                            value={input}
                        />
                        <Button
                            variant="primary"
                            className="m-2"
                            onClick={convertText}
                            disabled={input === ''}
                            style={{
                                minWidth: '250px',
                                minHeight: '60px',
                            }}
                        >
                            변환하기
                        </Button>
                        <Accordion>
                            <Button
                                variant="info"
                                className="m-2"
                                onClick={clearText}
                                disabled={input === ''}
                            >
                                입력창 지우기
                            </Button>
                            <Accordion.Toggle
                                as={Button}
                                variant="info"
                                className="m-2"
                                eventKey="0"
                            >
                                변환 옵션
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card bg="light" className="mx-2 my-0">
                                    <Card.Body>
                                        <Form>
                                            <Form.Group controlId="formRange1">
                                                <Form.Label className="m-0 p-0">
                                                    소리나는 대로 연음법칙 적용
                                                </Form.Label>
                                                <Form.Control
                                                    type="range"
                                                    step="10"
                                                    value={asItSoundsDegree}
                                                    onChange={
                                                        handleChangeForAsItSoundsDegree
                                                    }
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formRange2">
                                                <Form.Label className="m-0 p-0">
                                                    뒤에 오는 자음을 받침으로
                                                    중복
                                                </Form.Label>
                                                <Form.Control
                                                    type="range"
                                                    step="10"
                                                    value={
                                                        doubleConsonantAsEndSoundDegree
                                                    }
                                                    onChange={
                                                        handleChangeForDoubleConsonantAsEndSoundDegree
                                                    }
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formRange3">
                                                <Form.Label className="m-0 p-0">
                                                    자모를 비슷한 발음으로 변환
                                                </Form.Label>
                                                <Form.Control
                                                    type="range"
                                                    step="10"
                                                    value={
                                                        componentChangeDegree
                                                    }
                                                    onChange={
                                                        handleChangeForComponentChangeDegree
                                                    }
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formRange4">
                                                <Form.Label className="m-0 p-0">
                                                    의미없는 받침 추가
                                                </Form.Label>
                                                <Form.Control
                                                    type="range"
                                                    step="10"
                                                    value={
                                                        addExtraEndSoundDegree
                                                    }
                                                    onChange={
                                                        handleChangeForAddExtraEndSoundDegree
                                                    }
                                                />
                                            </Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                id="defaultCheckbox"
                                                label="완성형 글자만 허용"
                                                checked={onlyWansungChars}
                                                onChange={
                                                    handleChangeForOnlyWansungChars
                                                }
                                            />
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </Accordion>
                        {output !== '' && (
                            <>
                                <h5 className="mt-2 mb-0">변환된 문장:</h5>
                                <textarea
                                    className="form-control my-2"
                                    placeholder="변환된 문장들이 여기에 나타납니다."
                                    rows="8"
                                    onChange={handleOutputChange}
                                    value={output}
                                />
                                <Button
                                    variant="info"
                                    className="m-2"
                                    onClick={copyOutputToClipboard}
                                    style={{
                                        minWidth: '250px',
                                    }}
                                >
                                    클립보드에 복사
                                </Button>
                            </>
                        )}
                        <p>
                            한국인만 읽을 수 있는{' '}
                            <a href="https://tohanja.hanmesoft.com">
                                한자문장으로 바꾸려면 여기
                            </a>
                        </p>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                </div>
            </div>
            <ReactSnackBar Icon={<span>📝</span>} Show={snackbarShow}>
                <div className="text-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;변환된 텍스트가
                    복사되었습니다.
                </div>
            </ReactSnackBar>
            <BottomBar />
        </>
    );
}

export default App;
